import { Injectable } from '@angular/core';
import {
  BehaviorSubject, catchError,
  interval, map,
  Observable,
  startWith,
  Subscription,
  switchMap, throwError
} from "rxjs";
import {UserModel} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {ResponseModel} from "../models/response.model";
import {UserService} from "./user.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ChatService} from "./chat.service";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {TrackingHelperService} from "./tracking-helper.service";
import {TawkService} from "./tawk.service";
import {ConfigService} from "./config.service";
import * as Sentry from "@sentry/browser";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  BASE_URL: string;
  CASINO_ID: number;

  private _token = new BehaviorSubject<string | null>(null);
  private _cashierUrl = new BehaviorSubject<string | null>(null);

  private keepAliveSub?: Subscription;
  user!: UserModel | null;

  constructor(
    private http: HttpClient,
    private configs: ConfigService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private chatService: ChatService,
    private gaService: GoogleAnalyticsService,
    private trackingService: TrackingHelperService,
    private tawkService: TawkService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;
    this.userService.userObject.subscribe((u: UserModel | null) => {
      this.user = u;
    })

    /**
      if user is logged in (refreshed the page, navigated away, etc)
      - set sessionToken and cashierUrl from localStorage - todo in try catch to avoid errors when localstorage is not available
      - get userInfo: to see if the token is still valid (if yes, we get the userObjected in userService and wee start the keepalive
    */

    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (sessionToken) {
        this._token.next(sessionToken);
      }

      // TODO not to save cashierUrl in localStorage - if we know what the server will send, we could use that
      const cashierUrl = localStorage.getItem('cashierUrl');
      if (cashierUrl) {
        this._cashierUrl.next(cashierUrl);
      }
    } catch (error) {
      console.warn('Error accessing localStorage:', error);
    }




    if (this.token.value) {
      // console.log(this.token.value)

      this.userService.getUserInfo().subscribe({
          next: (data)=> {
            if ('result' in data && data.result) {
              const result = data.result as { user: UserModel }
              if (result && result.user) {
                //we have the user logged in and the token is still valid (ex. page reloaded/refreshed)
                this.startKeepAlive();

                //initialize Rival chat for logged in users
                if (this.configs.chatSystem === "rival"){
                  this.chatService.initialize(true)
                }

                //login for tawk chat - not needed, if user was logged in, tawk.to remembers
                // if (this.chatSystem === "tawk"){
                //   this.tawkService.LoginUserToTawk(result.user)
                // }

                //send event to GA ex. page_load that is not used for anything else TODO find out if we need this
                // this.gaService.event('page_load', undefined, undefined, undefined, undefined,{...this.trackingService.getAffiliateOptions() });
              }
            }
          },
          error: (err) => {
            //error handled in interceptor = resetUserSession
            this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1'), {toastClass: 'ngx-toastr yourclass'})
          }
        }
      )
    }
  }

  login(userLog: { username: string, password: string, mode:string }): Observable<ResponseModel> {
    //if the chat was opened as guest user - closes chat and ws
    this.chatService.toggleChat(false);

    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.login","params":userLog})
      .pipe(map((loginResponse: ResponseModel) => {
        if ('error' in loginResponse){
          throw loginResponse
        }

        if ('result' in loginResponse && loginResponse.result) {
          const result = loginResponse.result as { session: string; cashierUrl: string }
          if (result.session) {
            try {
              localStorage.setItem('sessionToken', result.session);
            } catch (error) {
              console.warn('Error accessing localStorage while login:', error);
            }
            this._token.next(result.session)
            this.startKeepAlive();

            //initialize Rival chat for logged in users
            if (this.configs.chatSystem === "rival") {
              this.chatService.initialize(true)
              // this.chatService.initialize() //for logged in users
            }

            this.userService.getUserInfo().subscribe({
              next: (response) => {
                if ('result' in response && response.result) {
                  const result = response.result as { user: UserModel }
                  if (result && result.user) {

                    this.gaService.event('login', undefined, undefined, undefined, undefined, {
                      user_id: result.user.login,
                      user_uuid: result.user.id, ...this.trackingService.getAffiliateOptions()
                    });

                    Sentry.setUser({
                      id: result.user.id,
                      username: result.user.login,
                      tracker: this.trackingService.getTracker() //user or undefined
                    })

                    //login for tawk chat
                    if (this.configs.chatSystem === "tawk") {
                      this.tawkService.LoginUserToTawk(result.user)
                    }

                    this.toastr.success(this.translate.instant('LOGIN.TOASTR_SUCCESS_MESSAGE', {username: result.user.fname}), this.translate.instant('LOGIN.TOASTR_SUCCESS_H1'), {toastClass: 'ngx-toastr yourclass'});
                  }
                }
              },
              error: (err) => {
                // this.toastr.error(`Failed to fetch user details: ${err.message}`, 'Error');
                this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_NOUSERINFO_MESSAGE'), this.translate.instant('LOGIN.TOASTR_ERROR_NOUSERINFO_H1'), {toastClass: 'ngx-toastr yourclass'})
              }
            })
          }
          if (result.cashierUrl) {
            try {
              localStorage.setItem('cashierUrl', result.cashierUrl);
            } catch (error) {
              console.warn('Error accessing localStorage while login:', error);
            }
            this._cashierUrl.next(result.cashierUrl)
          }
        }
        return loginResponse;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
      )

  }


  resetPassword(userLog:{email: string}): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"user.resetPassword","params": userLog})
      .pipe(
        map( (response: ResponseModel) => {
            if ('error' in response){
              throw response;
            }
            return response
          }
        ),
        catchError((error) => {
          // console.error('error from service:', error)
          return throwError(()=> error)
        })
      )
  }





  startKeepAlive() {
    this.keepAliveSub = interval( 60 * 1000) // refreshes user balance in every minute
      .pipe(
        startWith(0),
        switchMap(() => this.http.post<{result: {balance: number}}>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.keepAlive","params":{} })
        )
      )
      .subscribe(response => {
        // console.log('KA started');
        //checking balance to prevent TypeErrors occurring ($entry) TODO test if its better, if it still appears
        const balance = response?.result?.balance
        if (balance !== undefined){
          const user = this.user;
          if (user) {
            user.balance = balance;
            this.userService.updateUser(user);

            this.gaService.event('balance', undefined, undefined, undefined, undefined,{ user_balance: balance, ...this.trackingService.getAffiliateOptions() });

            //todo add event to tawk balance change
            if (this.configs.chatSystem === "tawk"){
              // this.tawkService.addEventToTawk('balance') //not working at all
              // this.tawkService.UpdateTawkUser(user); // Error: unauthorized_api_call https://community.tawk.to/t/unauthorized-api-call/1495
            }
          }
        } else {
          console.error('Invalid response structure:', response);
        }

        //todo add error handling? check if it still has sentry errors

      });
  }

  stopKeepAlive() {
    if (this.keepAliveSub) {
        this.keepAliveSub.unsubscribe();
        // console.log('KA stopped');
    }
  }



  logout(): Observable<{result: {}}> {
    return this.http.post<{result: {}}>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"session.logout","params":{}})
  }

  resetUserSession(): void {
    this.stopKeepAlive();

    try {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('cashierUrl');
    } catch(error){
      console.warn('Error accessing localStorage:', error);
    }

    if (this.user){
      this.userService.updateUser(null);
    }
    this._token.next(null);
    this._cashierUrl.next(null);

    //GA tracking
    this.gaService.event('logout',undefined, undefined, undefined, undefined, {...this.trackingService.getAffiliateOptions()});

    Sentry.setUser(null);


    //close Rival chat ws connection for logged in user
    if (this.configs.chatSystem === "rival"){
      this.chatService.toggleChat(false);
      this.chatService.closeWebSocket();
    }

    //logout from tawk chat
    if (this.configs.chatSystem === "tawk"){
      //todo
      //throws typerror after logout TypeError: Cannot read properties of undefined (reading '$refs') at a.autoFocusChatInput
      //https://community.tawk.to/t/cannot-read-properties-of-undefined-reading-refs/3419
      //https://community.tawk.to/t/uncaught-typeerror-cannot-read-properties-of-undefined-reading-0/3405
      this.tawkService.LogoutUserFromTawk(); //no log out? because of this typeError
    }


  }



  get token(): BehaviorSubject<string | null> {
    return this._token;
  }

  get cashierUrl(): BehaviorSubject<string | null> {
    return this._cashierUrl;
  }

}
