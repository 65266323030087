export const games=[
  {
    "id": 100,
    "name": "Lady Wolf Moon",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lady_wolf_moon.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 101,
    "name": "Hottest 666",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/hottest666.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 102,
    "name": "Wild Chicago",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_chicago.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 103,
    "name": "Wild Cash",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 104,
    "name": "Lucky Oak",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_oak.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 105,
    "name": "Wild Cash Dice",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash_dice.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 106,
    "name": "Wild Cash x9990",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash_x9990.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 107,
    "name": "Luck & Magic x999",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/luck_and_magic.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 108,
    "name": "Lucky Dama Muerta",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_dama_muerta.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 109,
    "name": "Gift Rush",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/gift_rush.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 110,
    "name": "Wild Tiger",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_tiger.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 111,
    "name": "Burning Chilli X",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/burning_chilli_x.png",
    "category": "Slots",
    "publisher": "Bgaming",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 112,
    "name": "Foxy Lady Forest",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/foxy_lady_forest.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 113,
    "name": "Funky Frogs",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/funky_frogs.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 114,
    "name": "Deep Sea Dazzle",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/deep_sea_dazzle.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 115,
    "name": "Lucky Cat",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_cat.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 116,
    "name": "Nodeposit Bonus",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/nodeposit_bonus.png",
    "category": "Slots",
    "publisher": "Affiliate",
    "details": {
      "publisher": "Affiliate"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 117,
    "name": "Cosmic Reels",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/cosmic_reels.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 119,
    "name": "God of Luxor",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/god_of_luxor.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 120,
    "name": "Bingo Slot",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/og_bingo_slot.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 121,
    "name": "Chipy",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/chipy.png",
    "category": "Slots",
    "publisher": "Affiliate",
    "details": {
      "publisher": "Affiliate"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 122,
    "name": "DragonEgg",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/dragonegg.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 123,
    "name": "LotterySlot",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lotteryslot.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 124,
    "name": "NodepositKings",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/nodeposit_kings.png",
    "category": "Slots",
    "publisher": "Affiliate",
    "details": {
      "publisher": "Affiliate"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 125,
    "name": "Jackowins",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/jackowins.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 126,
    "name": "SpiritOfTheSpins",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/spirit_of_the_spins.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 127,
    "name": "PirateBonus",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/piratebonus.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 128,
    "name": "CasinosAnalyzer",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/casinos_analyzer.png",
    "category": "Slots",
    "publisher": "Affiliate",
    "details": {
      "publisher": "Affiliate"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 129,
    "name": "BingoSlotChristmas",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/bingo_slot_christmas.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 2000,
    "name": "Delivery",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/delivery/delivery.png",
    "category": "crash",
    "publisher": "Instanet",
    "details": {
      "publisher": "Instanet"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 2001,
    "name": "Get Up!",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/get-up/get-up.png",
    "category": "crash",
    "publisher": "Instanet",
    "details": {
      "publisher": "Instanet"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 2002,
    "name": "Plane",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/plane/plane.png\n",
    "category": "crash",
    "publisher": "Instanet",
    "details": {
      "publisher": "Instanet"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 2003,
    "name": "Skull or Cash",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/skull-or-cash/skull-or-cash.png",
    "category": "crash",
    "publisher": "Instanet",
    "details": {
      "publisher": "Instanet"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 2004,
    "name": "Help",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/help/help.png",
    "category": "crash",
    "publisher": "Instanet",
    "details": {
      "publisher": "Instanet"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3000,
    "name": "Monkeys Go Bananas",
    "image": "https://images.ctfassets.net/xzs8i9nyyuo6/6ZZ65nc8JarG9UWOw93ZSW/6b85edfbdfb06fe40aef34efea90b227/MonkeysGoBananasMultimax_280x280.jpg",
    "category": "5 roll spin, avalanche",
    "publisher": "Yggdrasil",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3001,
    "name": "Joker 50",
    "image": "https://cdn.sanity.io/images/ol1w6ddr/paf-prod/30e05e1671150f10822dba2fe17ad7db81de57b5-900x900.jpg",
    "category": "3 roll spin",
    "publisher": "Yggdrasil",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3002,
    "name": "Big Top Terror",
    "image": "https://assets.aboutslots.com/uploads/assets/OSR_Fk_PG_Oy_Bear_slot_thumbnail_82f2465f87.jpg",
    "category": "Video Slot, 5 reels, 3 rows",
    "publisher": "Yggdrasil",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3003,
    "name": "Wild Storm Legionnaire",
    "image": "https://media.21.co.uk/images/games/wild-storm-legionnaire/wild-storm-legionnaire-square-auth.jpg",
    "category": "Video Slot, 5 reels, 4 rows",
    "publisher": "Yggdrasil",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3004,
    "name": "The Cursed Sea",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/thecursedsea.png",
    "category": "Video Slot, 5 reels, 3 rows",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3005,
    "name": "Riptide Treasures",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/riptidetreasures2.png",
    "category": "5 roll spin, avalanche",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3006,
    "name": "Ion",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/ion.png",
    "category": "5 roll spin, avalanche",
    "publisher": "TGS",
    "details": {
      "tag": "beta",
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "beta",
    "features": []
  },
  {
    "id": 3007,
    "name": "Ember",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/ember.png",
    "category": "5 roll spin, avalanche",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3008,
    "name": "Realm of Gaia",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/realm_of_gaia.png",
    "category": "Video Slot, 5 reels, 4 rows",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 3009,
    "name": "Realm of Whimsicality",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/realm_of_whimsicality.png",
    "category": "Video Slot, 5 reels, 4 rows",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 4000,
    "name": "Badge Blitz",
    "image": "https://www.bestcasinohq.com/wp-content/uploads/2024/10/15265_66f74bf446efa.jpg",
    "category": "Video Slot, 5 reels, 3 rows",
    "publisher": "Pragmatic",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 4001,
    "name": "Jackpot Hunter",
    "image": "https://mediumrare.imgix.net/28758241dc37505c094f446af08c7985c907eedbfb6ad466607dfc03e3223981?q=85",
    "category": "Video Slot, 5 reels, 4 rows",
    "publisher": "Pragmatic",
    "details": {},
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 5000,
    "name": "Zeus",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/zeus.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 5001,
    "name": "Plinko",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 5002,
    "name": "PlinkoChristmas",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko2.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  },
  {
    "id": 5003,
    "name": "Plinko3",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko3.png",
    "category": "Slots",
    "publisher": "TGS",
    "details": {
      "publisher": "TGS"
    },
    "launchUrl": null,
    "tag": "",
    "features": []
  }
]